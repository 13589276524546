<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3"></nav>
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Directory</h1>

            <div v-for="(stateRecords, stateName) in directoryData" :key="stateName">
                <div>
                    <h3>{{ stateName }}</h3>
                    <div>
                        <table class="table-responsive table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Email</th>
                                <th class="number">Phone</th>
                            </tr>
                            </thead>
                            <tbody v-for="(record, index) in stateRecords" :key="index">

                            <tr v-if="territoryQualifier(record)">
                                <th colspan="4">{{ record.customer_friendly_label }}</th>
                            </tr>

                            <tr>
                                <td>{{ record.regional_sales_rep }}</td>
                                <td>Regional Sales Rep</td>
                                <td>{{ record.regional_sales_rep_email }}</td>
                                <td class="number">{{ record.regional_sales_rep_phone }}</td>
                            </tr>
                            <tr>
                                <td>{{ record.sales_support_rep }}</td>
                                <td>Sales Support Rep</td>
                                <td>{{ record.sales_support_rep_email }}</td>
                                <td class="number">{{ record.sales_support_rep_phone }}</td>
                            </tr>
                            <tr>
                                <td>{{ record.measure_tech }}</td>
                                <td>Measure Tech</td>
                                <td>{{ record.measure_tech_email }}</td>
                                <td class="number">{{ record.measure_tech_phone }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </main>
    </div>
</template>

<script>
import materialCache from '@/cache/material.cache';
import cdiLocationCache from '@/cache/cdiLocation.cache';
import helpers from "@/store/helpers";
import {store} from "@/store/Tool.store";

export default {
    name: "Directory",
    data() {
        return {
            showForm: false,
            formFields: {
                materialID: {val: 4, qsField: '[numbers][m.inventory_category_id]'},
                cdiLocationID: {val: 1, qsField: '[numbers][po.cdi_location_id]'},
                json: {val: 1, qsField: '[json]'},
            },
            receiveFormFields: {
                userID: {val: this.$appStore.state.user.userID, qsField: '[user_id]'},
                receivedQty: {val: 0, qsField: '[received_qty]'},
                rawMaterialID: {val: 0, qsField: '[raw_material_id]'},
            },
            purchaseOrderID: 0,
            receiveFormTitle: 'Receive',
            cache: {
                materialCache,
                cdiLocationCache,
            },
            directoryData: [],
            receivingForm: false,
        }
    },
    created() {
        this.search();
    },
    methods: {
        search: function () {
            let scope = this;
            store.api('/directory/directory_list/', helpers.getQSPairs(this.formFields))
                .then(results => scope.directoryData = results);
        },
        territoryQualifier(record) {
            return ['CA', 'NV'].includes(record.state_ab);
        }
    }
}
</script>

<style scoped>

</style>